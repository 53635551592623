import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';

import { AbstractCHRFormControl } from 'app/forms/control/abstract-form-control';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';
import { ValidationFailureReason } from 'shared/enums/validation-failure-reason.enum';
import { cancelRequest as cancelSubscription } from 'app/util/util';
import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';
import { NavCarrierFormControl } from 'app/forms/control/form-field';

export class NavCarrierFormGroup<TValue = { [key: string]: any }> extends AbstractCHRFormControl implements CHRFormGroup {
  private _disabled = false;
  private _validation: NestedValidatorResult;
  private isPendingValidation = true;
  private _errors: {
    [key: string]: (string[] | ValidationErrorJSON[]);
    __self__: string[];
    __serverErrors__: ValidationErrorJSON[];
  };
  private _serverErrors: ValidationErrorJSON[] = [];
  private _valid: boolean;
  private validator: (value) => ValidatorResult;
  private validators: () => NestedValidatorResult;
  private _childValueChanges = new Subscription();
  private _childTouchedChanges = new Subscription();
  private _childValidationChanges = new Subscription();
  readonly touchedChanges = new Subject<boolean>();
  readonly valueChanges = new Subject<TValue>();
  readonly validationChanges = new Subject<FormGroupValidation>();
  readonly disabledChanges = new Subject<boolean>();
  readonly resetSubject = new Subject<void>();
  readonly changes: Observable<any> = Observable.merge(
    this.valueChanges,
    this.touchedChanges,
    this.validationChanges,
    this.disabledChanges
  ).map(() => this);

  protected children: { [key: string]: CHRFormControl } = {};

  get disabled() {
    return this._disabled || (Boolean(this.getParent()) && this.getParent().disabled);
  }

  get validation() {
    return this._validation;
  }

  get valid(): boolean {
    return this._valid;
  }

  get errors() {
    return this._errors;
  }

  get value(): TValue {
    return Object.entries(this.children).reduce((values, [name, field]) => ({
      ...values,
      [name]: field.value
    }), {}) as TValue;
  }

  get dirty(): boolean {
    return Object.entries(this.children).reduce((isDirty, [, field]) => isDirty || field.dirty, false);
  }

  get touched(): boolean {
    return Object.entries(this.children).reduce((isTouched, [, field]) => isTouched || field.touched, false);
  }

  constructor(structure: FormGroupStructure) {
    super();

    const children = buildFormGroupChildren(structure);
    this.addChildren(children);

    this._disabled = structure.disabled || false;
    this._serverErrors = structure.serverErrors || [];

    this.validators = this.reduceValidators(this.mergeFieldValidators());
    this.validator = this.reduceOwnValidators(structure.validators);
    this.setPendingValidation();
    this.validate();
  }

  markAsPristine() {
    Object.values(this.children).forEach(child => child.markAsPristine());
  }

  setValue(values: TValue) {
    Object.entries(values).forEach(([key, value]) => this.get(key).setValue(value));
  }

  setPendingValidation(recursive?: boolean) {
    this.isPendingValidation = true;
    if (recursive) {
      Object.values(this.children).forEach(child => child.setPendingValidation(true));
    }
  }

  protected clearPendingValidation() {
    this.isPendingValidation = false;
  }

  hasPendingValidation() {
    return this.isPendingValidation || Object.values(this.children).reduce((hasPendingValidation, child) => hasPendingValidation || child.hasPendingValidation(), false);
  }

  touch() {
    Object.values(this.children).forEach(control => control.touch());
  }

  private reduceOwnValidators(validators: ValidatorFn<any>[] = []) {
    return function combination(value) {
      return validators.reduce((output, validator) => ({ ...output, ...validator(value) }), {});
    };
  }

  addValidator(validator: ValidatorFn<any>) {
    this.validator = this.reduceOwnValidators([this.validator, validator]);
    this.validate();
  }

  validate() {
    if (!this.validators) {
      return;
    }
    if (!this.hasPendingValidation()) {
      return this.validation;
    }

    this.clearPendingValidation();

    const oldValidation = this._validation;
    this._validation = { ...this.validators(), __self__: this.validator(this.value), __server__: this._serverErrors };

    const isSelfValid = Object.values(this._validation.__self__).reduce((result, isValid) => (result && isValid), true);
    const selfErrors = Object.entries(this._validation.__self__).filter(([, value]) => !value).map(([name]) => name);

    // if self is already invalid don't bother reducing validation of children
    this._valid = isSelfValid && Object.entries(this.children).reduce((isValid, [, field]) => (isValid && field.valid), true);
    this._errors = Object.entries({ ...this.children })
      .filter(([, field]) => Array.isArray(field.errors) ? field.errors.length : Object.values(field.errors).length)
      .reduce((errObj, [name, field]) => ({ ...errObj, [name]: field.errors }), {
        __self__: selfErrors,
        __serverErrors__: this._serverErrors
      });

    if (JSON.stringify(oldValidation) !== JSON.stringify(this._validation)) {
      this.validationChanges.next({
        validation: this.validation,
        errors: this.errors,
        valid: this.valid
      });
    }
    return this._validation;
  }

  reset() {
    Object.values(this.children).forEach(child => child.reset());
    this.validate();
    this.resetSubject.next();
  }

  protected rebuildValidators() {
    this.validators = this.reduceValidators(this.mergeFieldValidators());
    this.validate();
  }

  private reduceValidators(validators: { [key: string]: () => ValidatorResult | NestedValidatorResult }) {
    return function combination() {
      return Object.entries(validators).reduce((output, [name, validator]) => ({ ...output, [name]: validator() }), {});
    };
  }

  private mergeFieldValidators() {
    return Object.entries(this.children).reduce((newValidators, [name, child]) => {
      return {
        ...newValidators,
        [name]: () => child.validate()
      };
    }, {});
  }

  disable(recursively = false) { // How does a disabled form affect the form value/validity?
    this._disabled = true;
    if (recursively) {
      Object.values(this.children).forEach(control => control.disable(recursively));
    }
    this.disabledChanges.next(true);
  }

  enable(recursively = false) {
    this._disabled = false;
    if (recursively) {
      Object.values(this.children).forEach(control => control.enable(recursively));
    }
    this.disabledChanges.next(false);
  }

  getChildKey(child: CHRFormControl): string {
    const childEntry = Object.entries(this.children).find(([, currentChild]: [string, CHRFormControl]) => currentChild === child);
    if (childEntry) {
      return childEntry[0];
    }
  }

  getPath(): string[] {
    if (this.getParent()) {
      return [
        ...this.getParent().getPath(),
        this.getParent().getChildKey(this).toString()
      ];
    }
    return [];
  }

  getChild(name): CHRFormControl | CHRFormGroup {
    return this.children[name];
  }

  addChild(name: string, newChild: CHRFormControl, suppressValidation?: boolean) {
    this.children[name] = newChild;
    newChild.setParent(this);
    this.setPendingValidation();

    cancelSubscription(this._childValueChanges);

    this._childValueChanges = Observable.merge(
      ...Object.values(this.children).map(child => child.valueChanges)
    ).subscribe(this.onChildValueChanges);

    cancelSubscription(this._childTouchedChanges);

    this._childTouchedChanges = Observable.merge(
      ...Object.values(this.children).map(child => child.touchedChanges)
    ).subscribe(this.onChildTouchedChanges);

    cancelSubscription(this._childValidationChanges);

    this._childValidationChanges = Observable.merge(
      ...Object.values(this.children).map(child => child.validationChanges)
    ).subscribe(this.onChildValidationChanges);

    if (!suppressValidation) {
      this.validators = this.reduceValidators(this.mergeFieldValidators());
    }

    this.valueChanges.next(this.value);

    if (!suppressValidation && !this.getParent()) {
      this.validate();
    }
  }

  private onChildValueChanges = () => {
    this.setPendingValidation();
    this.valueChanges.next(this.value);
    if (!this.getParent()) {
      this.validate();
    }
  };

  private onChildValidationChanges = () => {
    this.setPendingValidation();
    if (!this.getParent()) {
      this.validate();
    }
  };

  private onChildTouchedChanges = () => {
    this.touchedChanges.next(this.touched);
    if (!this.getParent()) {
      this.validate();
    }
  };

  private addChildren(structure: { [key: string]: CHRFormControl }) {
    Object.entries(structure).forEach(([key, value]) => {
      this.addChild(key, value, true);
    });
  }

  removeChild(name: string, suppressValidation?: boolean) {
    if (!this.children[name]) {
      return;
    }
    delete this.children[name];
    this.setPendingValidation();
    this.validators = this.reduceValidators(this.mergeFieldValidators());
    this.valueChanges.next(this.value);
    if (!this.getParent() && !suppressValidation) {
      this.validate();
    }
  }

  get(path: string | (string | number)[]) {
    if (typeof path === 'string') {
      const parts = path.split('.');
      return parts.reduce((targetChild: CHRFormGroup, part) => {
        try {
          return targetChild.getChild(part);
        } catch (e) {
          return null;
        }
      }, this as any);
    }
  }

  hasError(name: string): boolean {
    if (!this.touched) {
      return false;
    }

    const errors = this.errors.__self__ || [];
    const childErrors = Object.values(this.children).reduce((result, child) => result && child.hasError(name), false);

    return errors.includes(name) || childErrors;
  }

  hasErrors(): boolean {
    if (!this.touched) {
      return false;
    }

    const errors = this.errors.__self__ || [];
    const childErrors = Object.values(this.children).reduce((result, child) => result || child.hasErrors(), false);

    return Boolean(errors.length || childErrors);
  }

  hasServerError(path: string, reason: ValidationFailureReason): boolean {
    return Boolean(this._serverErrors && this._serverErrors.find(error =>
      error.propertyPath === path && error.failureReason === reason
    ));
  }
  setServerErrors(errors: ValidationErrorJSON[]) {
    this._serverErrors = errors || [];
    this.setPendingValidation(true);
    this.validate();
  }

  clearServerErrors() {
    this._serverErrors.length = 0;
    this.setPendingValidation(true);
    this.validate();
  }
}

const buildFormGroupChildren = (structure: FormGroupStructure) => {
  if (!structure.children) {
    return {};
  }

  return Object.entries(structure.children).reduce((children, [key, childStructure]) => {
    return {
      ...children,
      [key]: (
        (childStructure.type === FormStructureType.Field && new NavCarrierFormControl(childStructure)) ||
        (childStructure.type === FormStructureType.Group && new NavCarrierFormGroup(childStructure as FormGroupStructure)) ||
        (childStructure.type === FormStructureType.Array && new NavCarrierFormArrayChild(childStructure as FormArrayStructure))
      )
    };
  }, {});
};

export class NavCarrierFormArrayChild<ChildType extends CHRFormControl = CHRFormControl> extends AbstractCHRFormControl implements CHRFormGroup {
  private _disabled = false;
  private _validation: NestedValidatorResult;
  private isPendingValidation = true;
  private _errors: { [key: string]: string[] };
  private _serverErrors: ValidationErrorJSON[];
  private _valid: boolean;
  private validators: () => NestedValidatorResult;
  private validator: (value) => ValidatorResult;
  private _childValueChanges = new Subscription();
  private _childTouchedChanges = new Subscription();
  private _childValidationChanges = new Subscription();
  readonly touchedChanges = new Subject();
  readonly valueChanges = new Subject();
  readonly validationChanges = new Subject<FormGroupValidation>();
  readonly disabledChanges = new Subject();
  readonly changes = Observable.merge(
    this.valueChanges,
    this.touchedChanges,
    this.validationChanges,
    this.disabledChanges
  ).map(() => this);

  private children: ChildType[] = [];

  getChildren() {
    return this.children;
  }

  get disabled() {
    return this._disabled || (!!this.getParent() && this.getParent().disabled);
  }

  get validation() {
    return this._validation;
  }

  get valid(): boolean {
    return this._valid;
  }

  get errors() {
    return this._errors;
  }

  get value(): { [key: string]: any } {
    return this.children.reduce((values, field, key) => ({ ...values, [key]: field.value }), {});
  }

  get dirty(): boolean {
    return this.children.reduce((isDirty, field) => isDirty || field.dirty, false);
  }

  get touched(): boolean {
    return this.children.reduce((touched, field) => touched || field.touched, false);
  }

  constructor(structure: FormArrayStructure) {
    super();

    const children = buildFormArrayChildren(structure);
    this.addChildren(children);

    this._disabled = structure.disabled || false;
    this._serverErrors = structure.serverErrors || [];

    this.validators = this.reduceValidators(this.mergeFieldValidators());
    this.validator = this.reduceOwnValidators(structure.validators);
    this.setPendingValidation();
    this.validate();
  }

  markAsPristine() {
    this.children.forEach(child => child?.markAsPristine());
  }

  setValue(values: any[]) {
    values.forEach((value, key) => this.getChild(key).setValue(value));
  }

  setPendingValidation(recursive?: boolean) {
    this.isPendingValidation = true;
    if (recursive) {
      this.children.forEach(child => child.setPendingValidation(true));
    }
  }

  protected clearPendingValidation() {
    this.isPendingValidation = false;
  }

  hasPendingValidation() {
    return this.isPendingValidation || this.children.reduce((hasPendingValidation, child) => hasPendingValidation || child.hasPendingValidation(), false);
  }

  touch() {
    this.children.forEach(child => child.touch());
  }

  validate() {
    if (!this.hasPendingValidation()) {
      return this.validation;
    }

    this.clearPendingValidation();

    this.validators = this.reduceValidators(this.mergeFieldValidators());
    const oldValidation = this._validation;

    this._validation = { ...this.validators(), __self__: this.validator(this.value) };

    const isSelfValid = Object.values(this._validation.__self__).reduce((result, isValid) => (result && isValid), true);
    const selfErrors = Object.entries(this._validation.__self__).filter(([, value]) => !value).map(([name]) => name);

    // if self is already invalid don't bother reducing validation of children
    this._valid = isSelfValid && this.children.reduce((isValid, field) => (isValid && field.valid), true);
    this._errors = this.children
      .filter((field) => Array.isArray(field.errors) ? field.errors.length : Object.values(field.errors).length)
      .reduce((errObj, field, key) => ({ ...errObj, [key]: field.errors }), {
        __self__: selfErrors
      });

    if (JSON.stringify(oldValidation) !== JSON.stringify(this._validation)) {
      this.validationChanges.next({
        validation: this.validation,
        errors: this.errors,
        valid: this.valid
      });
    }

    return this._validation;
  }

  addValidator(validator: ValidatorFn<any>) {
    this.validator = this.reduceOwnValidators([this.validator, validator]);
    this.validate();
  }

  reset() {
    this.children.forEach(child => child.reset());
    this.validate();
  }

  private reduceValidators(validators: { [key: string]: () => ValidatorResult | NestedValidatorResult }) {
    return function combination() {
      return Object.entries(validators).reduce((output, [name, validator]) => ({ ...output, [name]: validator() }), {});
    };
  }

  private mergeFieldValidators() {
    return this.children.reduce((newValidators, child, key) => {
      return {
        ...newValidators,
        [key]: () => child.validate()
      };
    }, {});
  }

  private reduceOwnValidators(validators: ValidatorFn<any>[] = []) {
    return function combination(value) {
      return validators.reduce((output, validator) => ({ ...output, ...validator(value) }), {});
    };
  }

  disable(recursively = false) { // How does a disabled form affect the form value/validity?
    this._disabled = true;
    if (recursively) {
      this.children.forEach(child => child.disable(recursively));
    }
    this.disabledChanges.next(true);
  }

  enable(recursively = false) {
    this._disabled = false;
    if (recursively) {
      this.children.forEach(child => child.enable(recursively));
    }
    this.disabledChanges.next(false);
  }

  getPath(): string[] {
    if (this.getParent()) {
      return [
        ...this.getParent().getPath(),
        this.getParent().getChildKey(this).toString()
      ];
    }
    return [];
  }

  getChildKey(item: ChildType) {
    return this.children.indexOf(item);
  }

  getChild(index: number) {
    return this.children[index];
  }

  addChild(newChild: ChildType, suppressValidation?: boolean) {
    this.children.push(newChild);
    newChild.setParent(this);
    this.setPendingValidation();

    this.resetSubscriptions();

    this.valueChanges.next(this.value);

    if (!suppressValidation && !this.getParent()) {
      this.validate();
    }
  }

  private resetSubscriptions() {
    cancelSubscription(this._childValueChanges);

    this._childValueChanges = Observable.merge(
      ...this.children.map(child => child.valueChanges)
    ).subscribe(this.onChildValueChanges);

    cancelSubscription(this._childTouchedChanges);

    this._childTouchedChanges = Observable.merge(
      ...this.children.map(child => child.touchedChanges)
    ).subscribe(this.onChildTouchedChanges);

    cancelSubscription(this._childValidationChanges);

    this._childValidationChanges = Observable.merge(
      ...Object.values(this.children).map(child => child.validationChanges)
    ).subscribe(this.onChildValidationChanges);
  }

  private onChildValueChanges = () => {
    this.valueChanges.next(this.value);
    if (!this.getParent()) {
      this.validate();
    }
  };

  private onChildValidationChanges = () => {
    this.setPendingValidation();
    if (!this.getParent()) {
      this.validate();
    }
  };

  private onChildTouchedChanges = () => {
    this.touchedChanges.next(this.touched);
    if (!this.getParent()) {
      this.validate();
    }
  };

  protected addChildren(children: ChildType[]) {
    children.forEach(child => this.addChild(child, true));
    this.setPendingValidation();
  }

  removeChildAt(index: number) {
    const child = this.children[index];
    child.setParent(null);
    this.setPendingValidation();

    this.children = [
      ...this.children.slice(0, index),
      ...this.children.slice(index + 1)
    ];

    this.resetSubscriptions();

    this.valueChanges.next(this.value);

    if (!this.getParent()) {
      this.validate();
    }
  }

  removeChild(child: ChildType) {
    this.setPendingValidation();
    const index = this.children.indexOf(child);
    this.removeChildAt(index);
  }

  hasError(name) {
    if (this.children.length && !this.touched) {
      return false;
    }
    const errors = this.errors.__self__ || [];
    const childErrors = this.children.reduce((result, child) => result && child.hasError(name), false);

    return errors.includes(name) || childErrors;
  }

  hasErrors() {
    if (!this.touched) {
      return false;
    }

    return Boolean(this.errors.__self__.length) || this.children.reduce((result, child) => result || child.hasErrors(), false);
  }

  hasServerError(path: string, reason: ValidationFailureReason): boolean {
    return Boolean(this._serverErrors.find(error =>
      error.propertyPath === path && error.failureReason === reason
    ));
  }

  clearServerErrors() {
    this._serverErrors.length = 0;
    this.validate();
  }
}

const buildFormArrayChildren = (structure: FormArrayStructure) => {
  if (!structure.children) {
    return [];
  }

  if (structure.childClass) {
    return structure.children.reduce((children, childStructure) => [
      ...children,
      new (structure.childClass as typeof NavCarrierFormControl)(childStructure)
    ], []);
  }

  return structure.children.reduce((children, childStructure) => {
    return [
      ...children,
      (
        (childStructure.type === FormStructureType.Field && new NavCarrierFormControl(childStructure)) ||
        (childStructure.type === FormStructureType.Group && new NavCarrierFormGroup(childStructure as FormGroupStructure)) ||
        (childStructure.type === FormStructureType.Array && new NavCarrierFormArrayChild(childStructure as FormArrayStructure))
      )
    ];
  }, []);
};
